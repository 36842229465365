import React from "react"

//plugin
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer"

// assets
import IllustAccounts from "../assets/images/tos/accounts.png"
import IllustFees from "../assets/images/tos/fees.png"
import IllustPlatform from "../assets/images/tos/platform.png"
import IllustServices from "../assets/images/tos/services.png"

const style = StyleSheet.create({
  titleHolder: {
    width: "100%",
    paddingBottom: 40,
  },
  contentHolder: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  titleContent: {
    fontSize: 24,
    fontWeight: "bold",
  },
  subTextHolder: {
    paddingLeft: 60,
  },
  text: {
    marginTop: 10,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,

    fontSize: 14,
  },
  contentImg: {
    display: "block",
    width: "100%",
    maxWidth: 227,
    marginTop: 0,
    marginRight: "auto",
    marginBottom: 0,
    marginLeft: "auto",
  },
  contentContainer: {
    paddingTop: 30,
    paddingRight: 30,
    paddingBottom: 30,
    paddingLeft: 30,
  },
})

const PrivacyList = props => {
  const content = props.content

  const getImage = tosImage => {
    switch (tosImage) {
      case "Platform":
        return IllustPlatform

      case "Fees":
        return IllustFees

      case "Accounts":
        return IllustAccounts

      case "Services":
        return IllustServices

      default:
        return null
    }
  }

  return (
    <Document file="tos.pdf">
      <Page size="A4" style={style.contentContainer}>
        <View>
          {content.map(cont => {
            let img = cont.href ? getImage(cont.href) : null

            return (
              <View
                id={cont.name}
                className="content-holder"
                style={style.contentHolder}
              >
                <Text className="title-privacy" style={style.titlePrivacy}>
                  {cont.href === "Terms" ? (
                    cont.name
                  ) : (
                    <>
                      {cont.id}.&nbsp;{cont.name}
                    </>
                  )}
                </Text>
                <Image
                  src={img}
                  alt={cont.image ? "illustration" : null}
                  className="img-privacy"
                  style={style.contentImg}
                  safePath={img}
                />
                {cont.content.map(sub => {
                  const subText = sub.text.replace(/(<([^>]+)>)/gi, "")

                  return (
                    <View>
                      <Text style={style.text}>{subText}</Text>
                      <View
                        className="sub-text-holder"
                        style={style.subTextHolder}
                      >
                        {sub.textSub.map(textSub => {
                          const subSubText = textSub.replace(
                            /(<([^>]+)>)/gi,
                            ""
                          )

                          return <Text style={style.text}>{subSubText}</Text>
                        })}
                      </View>
                    </View>
                  )
                })}
                <Text style={style.text}>
                  {cont.subcontent ? cont.subcontent : null}
                </Text>
                <Text style={style.text}>
                  {cont.subsubcontent ? cont.subsubcontent : null}
                </Text>
              </View>
            )
          })}
        </View>
      </Page>
    </Document>
  )
}

export default PrivacyList
