import React, { useState } from "react"

//packages
import ReactHtmlParser from "react-html-parser"
import { pdf } from "@react-pdf/renderer"
import {saveAs} from "file-saver"

//components
import Layout from "../components/layout"
import SEO from "../components/seo"
import PrivacyCard from "../components/privacyCard"
import PrivacyMenu from "../components/privacyMenu"
import TableOfContent from "../components/table-of-content"
import TosPDF from "../components/tosPdf"
import SectionSearch from "../components/section-search"
import Banner from "../components/banner"
import Cta from "../components/section-cta"

//assets
import IconSum from "../assets/images/tos/icon-summary.svg"
import IconNeed from "../assets/images/tos/icon-need.svg"
import IconMSum from "../assets/images/tos/icon-blue-sum.svg"
import IconMNeed from "../assets/images/tos/icon-blue-need.svg"
import iconDown from "../assets/images/privacy-policy/arrow-down.svg"
import IllustAccounts from "../assets/images/tos/accounts.svg"
import IllustFees from "../assets/images/tos/fees.svg"
import IllustPlatform from "../assets/images/tos/platform.svg"
import IllustServices from "../assets/images/tos/services.svg"
import PanelArt from "../assets/images/illustration-tos.svg"

//json
import tos from "../data/tos.json"

const Terms = ({ location }) => {
  const [keyword, setKeyword] = useState("")

  const handleSearch = e => {
    setKeyword(e.target.value)
  }

  const dynamicSearch = () => {
    const filters = tos.filter(names =>
      names.name.toLowerCase().includes(keyword.toLowerCase())
    )
    return filters
  }

  const handleClear = () => {
    setKeyword("")
  }

  const cardContent = [
    {
      icon: IconSum,
      title: `Why read the Terms of Use? `,
      description:
        "Reading the Terms of Use will give you a clear understanding of what you can and cannot do with Servbees and its functions. It also serves to protect you and the Servbees Team, as it prevents the misuse of the app and all the services offered.",
    },
    {
      icon: IconNeed,
      title: "Our Agreement",
      description:
        "By creating an account and using the app, you are expressing to have read, understood, and accepted the Terms and Conditions of Servbees.  ",
    },
  ]

  const getImage = tosImage => {
    switch (tosImage) {
      case "Platform":
        return IllustPlatform

      case "Fees":
        return IllustFees

      case "Accounts":
        return IllustAccounts

      case "Services":
        return IllustServices

      default:
        return null
    }
  }

  const tosContent = [...tos]

  const backToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  const handleDownloadTOSPdf = () => {
    saveAs(`/assets/terms-of-use/SERVBEES_Terms_of_Use_2022_JUL.pdf`, 'SERVBEES_Terms_of_Use_2022_JUL', 'pdf')
  }

  return (
    <Layout className="terms bg-design">
      <SEO
        pageTitle="Terms Of Use"
        title="Terms Of Use | Servbees"
        description="Ready to use Servbees? Be a responsible Buzzybee by reading the terms and conditions before using our app."
      />
      {keyword === "" ? (
        <TableOfContent
          tableContent={tosContent}
          location={"/terms-of-use"}
          panelImg={PanelArt}
          href={"Terms"}
        />
      ) : (
        ""
      )}
      <Banner
        title="Terms of Use"
        keyword={keyword}
        handleSearch={handleSearch}
        handleClear={handleClear}
      />
      <div className="terms-wrapper">
        {keyword !== "" ? (
          <>
            <SectionSearch
              content={dynamicSearch()}
              keyword={keyword}
              location={"/terms-of-use"}
            />
          </>
        ) : (
          <>
            <div className="cards-section">
              <div className="container cards-holder hide-on-mobile">
                {cardContent.map(privacy => {
                  return (
                    <PrivacyCard
                      icon={privacy.icon}
                      title={privacy.title}
                      description={privacy.description}
                    />
                  )
                })}
              </div>
              <div className="mobile-card-holder hide-on-desktop">
                <div className="card">
                  <img src={IconMSum} alt="like icon" />
                  <p className="card-mobile-title">{cardContent[0].title}</p>
                  <p className="card-mobile-desc">
                    {cardContent[0].description}
                  </p>
                </div>
                <div className="card">
                  <img src={IconMNeed} alt="like icon" />
                  <p className="card-mobile-title">{cardContent[1].title}</p>
                  <p className="card-mobile-desc">
                    {cardContent[1].description}
                  </p>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="sub-title-holder">
                <p className="sub-title">Servbees Terms of Use</p>
                <img src={iconDown} alt="" />
              </div>
              <div className="privacy-content-holder">
                <PrivacyMenu location={"/terms-of-use"} />
                <div className="privacy-content">
                  <div className="download-holder">
                    <p>Last updated: 5 July 2022</p>
                    <button className="btn-download" onClick={handleDownloadTOSPdf}>
                      Download PDF
                    </button>
                    <button
                      className="btn-mobile-download hide-on-desktop"
                      onClick={handleDownloadTOSPdf}
                    >
                      &nbsp;
                    </button>
                  </div>
                  {tosContent.map(tos => {
                    let img = tos.href ? getImage(tos.href) : null
                    return (
                      <div id={tos.href} className="content-holder">
                        <p className="title-privacy">
                          {tos.href === "Terms" || tos.href === "AppendixA" ? (
                            tos.name.toLowerCase()
                          ) : (
                            <>
                              {tos.id}.&nbsp;{tos.name.toLowerCase()}
                            </>
                          )}
                        </p>
                        {tos.href ? (
                          <img src={img} alt="" className="img-tos" />
                        ) : (
                          ""
                        )}
                        {tos.content.map(sub => {
                          return (
                            <>
                              <ul className="sub">
                                {sub.text ? (
                                  <>
                                    {sub.text.map(item => (
                                      <li>{ReactHtmlParser(item)}</li>
                                    ))}
                                  </>
                                ) : (
                                  ""
                                )}
                              </ul>
                              <ul className="text-bullet">
                                {sub.textBullet ? (
                                  <>
                                    {sub.textBullet.map(item => (
                                      <li>{ReactHtmlParser(item)}</li>
                                    ))}
                                  </>
                                ) : (
                                  ""
                                )}
                              </ul>
                              <ul className="list">
                                {sub.listDef?.map(item => {
                                  return (
                                    <li>
                                      {ReactHtmlParser(item.text)}
                                      <ul className="subsub-content">
                                        {item.subsubcontent?.map(item => {
                                          return (
                                            <li>{ReactHtmlParser(item)}</li>
                                          )
                                        })}
                                      </ul>
                                    </li>
                                  )
                                })}
                              </ul>
                              <ul className="list-num">
                                {sub.listNum?.map(item => {
                                  return (
                                    <li>
                                      {ReactHtmlParser(item.list)}
                                      <ul className="subsub-number">
                                        {item.subsubnumber?.map(item => {
                                          return (
                                            <li>
                                              {ReactHtmlParser(item.number)}
                                              <ul className="subsub-letter">
                                                {item.subletter?.map(item => {
                                                  return (
                                                    <li>
                                                      {ReactHtmlParser(item)}
                                                    </li>
                                                  )
                                                })}
                                              </ul>
                                            </li>
                                          )
                                        })}
                                      </ul>
                                    </li>
                                  )
                                })}
                              </ul>
                              <ul className="bullet-list">
                                {sub.bullet?.map(item => {
                                  return (
                                    <li>
                                      {ReactHtmlParser(item.bulletList)}
                                      <ul className="subsub-bullet">
                                        {item.subsubbullet?.map(item => {
                                          return (
                                            <li>{ReactHtmlParser(item)}</li>
                                          )
                                        })}
                                      </ul>
                                    </li>
                                  )
                                })}
                              </ul>
                              <ul className="list">
                                {sub.list ? (
                                  <>
                                    {sub.list.map(item => (
                                      <li>{ReactHtmlParser(item)}</li>
                                    ))}
                                  </>
                                ) : (
                                  ""
                                )}
                              </ul>
                              <ul className="numbered-list">
                                {sub.numberedList ? (
                                  <>
                                    {sub.numberedList.map(item => (
                                      <li>{ReactHtmlParser(item)}</li>
                                    ))}
                                  </>
                                ) : (
                                  ""
                                )}
                              </ul>
                              <ul className="bullet-list">
                                {sub.bulletList ? (
                                  <>
                                    {sub.bulletList.map(item => (
                                      <li>{ReactHtmlParser(item)}</li>
                                    ))}
                                  </>
                                ) : (
                                  ""
                                )}
                              </ul>
                              <ul className="letter-list">
                                {sub.letterList ? (
                                  <>
                                    {sub.letterList.map(item => (
                                      <li>{ReactHtmlParser(item)}</li>
                                    ))}
                                  </>
                                ) : (
                                  ""
                                )}
                              </ul>
                            </>
                          )
                        })}
                        <p>{tos.subcontent}</p>
                      </div>
                    )
                  })}
                  <div className="return-holder">
                    <div className="return">
                      <p onClick={backToTop} role="presentation">
                        Return to Top
                      </p>
                    </div>
                    <div className="download-holder">
                      <p>Last updated: 5 July 2022</p>
                      <button className="btn-download" onClick={handleDownloadTOSPdf}>
                        Download PDF
                      </button>
                      <button
                        className="btn-mobile-download hide-on-desktop"
                        onClick={handleDownloadTOSPdf}
                      >
                        &nbsp;
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <Cta />
    </Layout>
  )
}

export default Terms
